import { Injectable } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemesService {

  private readonly THEME_KEY = 'theme';
  private currentThemeSubject = new BehaviorSubject<string>('default');
  currentTheme$ = this.currentThemeSubject.asObservable();

  constructor(private themeService: NbThemeService) {
    // Initialize theme on service creation
    this.initializeTheme();
  }

  private initializeTheme() {
    // Retrieve saved theme from localStorage
    const savedTheme = localStorage.getItem(this.THEME_KEY) || 'default';
    this.setTheme(savedTheme);
  }

  setTheme(themeName: string) {
    // Set theme in Nebular
    this.themeService.changeTheme(themeName);

    // Save theme to localStorage
    localStorage.setItem(this.THEME_KEY, themeName);

    // Update current theme subject
    this.currentThemeSubject.next(themeName);
  }

  getCurrentTheme(): string {
    return this.currentThemeSubject.value;
  }

  // Optional: Get available themes
  getAvailableThemes(): string[] {
    return [
      'default',
      'dark',
      'cosmic',
      'corporate'  // Add your Nebular themes here
    ];
  }
}
